import Web3 from 'web3';
import titanNftContractABI from '../../assets/contracts/ethereum/nft-titan-contract-abi-ethereum.json';
import { AbiItem } from 'web3-utils';
import { useProvider } from './useProvider';

export const useNftContract = () => {
    const { ethereumProvider } = useProvider();
    const web3 = new Web3(ethereumProvider());
    const titanNftContractAddress = process.env.REACT_APP_TITAN_NFT_CONTRACT_ADDRESS_ETHEREUM;
    const contractInstance = new web3.eth.Contract(titanNftContractABI as AbiItem[], titanNftContractAddress as string);

    const getUnclaimedTokens = async (tokenId: number) => {
        try {
            const hasOwner = await contractInstance.methods._isTokenMinted(tokenId).call();
            return hasOwner;
        } catch (e) {
            console.log(e);
        }
    };

    return {
        getUnclaimedTokens,
    };
};
