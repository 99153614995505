export const getClaimTokens = async (claimAddress: string, captchaToken: any) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            claimerAddress: claimAddress,
            captchaToken: captchaToken,
        }),
    };
    try {
        const responseData = await fetch(process.env.REACT_APP_BASE_URL + 'api/faucet/claim', requestOptions);
        return await responseData.json();
    } catch (e) {
        console.log(e);
        return { message: '503: Service Unavailable. \n Try again later!' };
    }
};
