import { useContext, useEffect, useState } from 'react';
import placeholderNFT from '../../assets/images/placeholders/placeholder-titan.png';
import { AuthContext } from '../../context/AuthContext';
import { transferTitanCoin } from '../utils/transferTitanCoin';
import { NftSupplyInformation } from '../NftSupplyInformation';
import { useTokenContract } from '../hooks/useTokenContract';
import titanCoinContractABI from '../../assets/contracts/polygon/titan-coin-contract-abi.json';
import { AbiItem } from 'web3-utils';
import Web3 from 'web3';
import { useProvider } from '../hooks/useProvider';
import { Alert } from '../utils/Alert';

export const CardBasket = () => {
    const { userInfo } = useContext(AuthContext);
    const [isBuying, setIsBuying] = useState<boolean>(false);
    const [buyStatus, setBuyStatus] = useState<string>('');
    const [errorStatus, setErrorStatus] = useState<string>('');
    const [confirmation, setConfirmation] = useState<boolean>(false);
    const { getCurrentProvider } = useProvider();
    const { getUserBalance } = useTokenContract();
    const [titanTokenBalance, setTitanTokenBalance] = useState<number>(0);
    const insufficientLiquidity = titanTokenBalance < 10;
    const coinContractAddress = process.env.REACT_APP_TITAN_COIN_CONTRACT_ADDRESS_POLYGON;
    const [transactionHash, setTransactionHash] = useState<string>('');
    const isDisabled = insufficientLiquidity || isBuying;
    const web3 = new Web3(getCurrentProvider());

    (async () => {
        const titanBalance = await getUserBalance(
            titanCoinContractABI as AbiItem[],
            coinContractAddress as string,
            userInfo.account as string
        );
        setTitanTokenBalance(titanBalance);
    })();

    useEffect(() => {
        const getReceipt = async () => {
            if (transactionHash) {
                const receipt = await web3.eth.getTransactionReceipt(transactionHash);
                return setConfirmation(receipt?.status);
            }
            return setConfirmation(false);
        };
        getReceipt();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isBuying, transactionHash]);

    useEffect(() => {
        if (isBuying) {
            return setBuyStatus(`Buying, don't refresh`);
        }
        if (insufficientLiquidity) {
            return setBuyStatus('Insufficient funds');
        } else {
            return setBuyStatus('Buy Titan');
        }
    }, [insufficientLiquidity, isBuying]);

    return (
        <div className="card-basket">
            <section className="card-basket__section">
                <img className="card-basket__img" src={placeholderNFT} alt="NFT placeholder" />
            </section>

            <section className="card-basket__section card-basket__section--buy">
                <h2 className="card-basket__title">Titan NFT Collection</h2>

                <NftSupplyInformation />

                <button
                    disabled={isDisabled}
                    className={`btn btn--rounded ${isDisabled ? 'btn-disabled' : 'btn-primary'}`}
                    onClick={() =>
                        transferTitanCoin(
                            getCurrentProvider(),
                            userInfo?.account!,
                            setIsBuying,
                            setTransactionHash,
                            setErrorStatus
                        )
                    }
                >
                    {buyStatus}
                </button>

                {errorStatus && <Alert message={errorStatus} type={'error'} />}
                {confirmation && <Alert message="You succesfully bought a Titan-NFT" type={'success'} />}
            </section>
        </div>
    );
};
