import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { NftTitanContract } from "../api/NftTitanContract";
import titanTokenPlaceholder from "../assets/images/placeholders/placeholder-titan-token.png";
import { Icon } from "./utils/Icon";

type SupplyDataItemProps = {
    title: string;
    value: string | number;
    shouldDisplayToken?: boolean;
};

const SupplyDataItem = (props: SupplyDataItemProps) => {
    return (
        <li className="supply-data__item">
            <h3 className="supply-data__title">{props.title}</h3>
            <p className="supply-data__value">
                {props.value === "loading" ? <Icon type="spinner" /> : props.value}
                {props.shouldDisplayToken && <img className="supply-data__img" src={titanTokenPlaceholder} alt="Titan-token" />}
            </p>
        </li>
    );
};

export const NftSupplyInformation = () => {
    const { getSupplyInformation } = NftTitanContract();
    const { isLoading, error, data, isSuccess } = useQuery("nfts", () => getSupplyInformation());
    const [totalSupply, setTotalSupply] = useState("n/a");
    const [remainingSupply, setRemainingSupply] = useState<string | number>("n/a");

    useEffect(() => {
        if (isLoading) {
            setTotalSupply("loading");
            setRemainingSupply("loading");
        }
        if (error) {
            setTotalSupply("n/a");
            setRemainingSupply("n/a");
        }
        if (isSuccess) {
            setTotalSupply(data.data.contractInfos[0].totalSupply);
            setRemainingSupply(data.data.contractInfos[0].totalSupply - data.data.contractInfos.length);
        }
    }, [data?.data?.contractInfos, error, isLoading, isSuccess]);

    return (
        <ul className="supply-data">
            <SupplyDataItem title="Status" value={remainingSupply >= 1 ? "Available" : "Sold out"} />
            <SupplyDataItem title="Total supply" value={totalSupply} />
            <SupplyDataItem title="Remaining" value={remainingSupply} />
            <SupplyDataItem title="Price" value={10} shouldDisplayToken={true} />
        </ul>
    );
};
