import { createContext, useState } from "react";
import { UserInfoProps } from "../types/UserInfoProps";

export const AuthContext = createContext<any>(null);

export const AuthContextProvider = (props: any) => {
  const initializeState = JSON.parse(localStorage.getItem("userAccount")!);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(initializeState || false);
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(false);
  const [authError, setAuthError] = useState<string>("");
  const [userInfo, setUserInfo] = useState<UserInfoProps | null>(initializeState || null);

  const providerValue = {
    isAuthenticated,
    setIsAuthenticated,
    isAuthenticating,
    setIsAuthenticating,
    authError,
    setAuthError,
    userInfo,
    setUserInfo
  };

  return (
    <AuthContext.Provider value={providerValue}>
      {props.children}
    </AuthContext.Provider>
  );
};
