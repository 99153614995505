export const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <nav>
                    <a
                        className="navigation__item link"
                        href="https://wiki.koios.world/important-info/how-to-revive-a-titan-nft"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Instructions
                    </a>
                    <a
                        className="navigation__item link"
                        href="https://wiki.koios.world/important-info/disclaimers"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Disclaimer
                    </a>
                </nav>
            </div>
        </footer>
    );
};
