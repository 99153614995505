import { useContext } from 'react';
import { ClaimContext } from '../../context/ClaimContext';

export const ClaimConfirmation = () => {
    const { setClaimConfirmation } = useContext(ClaimContext);

    return (
        <section className="claim-confirmation">
            <h2 className="claim-confirmation__subtitle">Claim successful</h2>
            <h1 className="claim-confirmation__title">Welcome Titan</h1>
            <button className="btn btn-primary link" onClick={() => setClaimConfirmation(false)}>
                Back to Claim
            </button>
        </section>
    );
};
