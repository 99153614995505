import Web3 from "web3";

export const rinkebyChain = {
  chainId: "0x4",
  chainName: "Ethereum Rinkeby Testnet RPC",
  rpcUrls: ["https://rinkey.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"],
  blockExplorerUrls: ["https://rinkey.etherscan.io"],
  nativeCurrency: {
    name: "Rinkeby",
    symbol: "ETH",
    decimals: 18,
  },
};

export const ethereumChain = {
  chainId: "0x1",
  chainName: "Ethereum Mainnet",
  rpcUrls: ["https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"],
  blockExplorerUrls: ["https://etherscan.io/"],
  nativeCurrency: {
    name: "Ethereum",
    symbol: "ETH",
    decimals: 18,
  },
};

export const polygonChain = {
  chainId: Web3.utils.toHex("137"),
  chainName: "Polygon Mainnet",
  nativeCurrency: {
    name: "MATIC",
    symbol: "MATIC",
    decimals: 18,
  },
  rpcUrls: ["https://rpc-mainnet.matic.network"],
  blockExplorerUrls: ["https://polygonscan.com"],
};
