import { CardBasket } from '../components/cards/CardBasket';
import { PageHeader } from '../components/layout/PageHeader';

export const BuyPage = () => {
    return (
        <main className="buy-page container">
            <PageHeader title="Become a Titan" />
            <CardBasket />
        </main>
    );
};
