type AlertProps = {
    message: string;
    type: 'success' | 'warning' | 'error';
}

export const Alert = ({ message, type }: AlertProps) => {
    return (
        <div className={`alert alert-${type}`}>
            {message}
        </div>
    )
}