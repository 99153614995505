import { NftSupplyQuery } from "./queries/NftSupplyQuery";


export const NftTitanContract = () => {

  const getSupplyInformation = async () => {
    const url = 'https://api.thegraph.com/subgraphs/name/koiosonline/titan-nft-ethereum';
    const query = NftSupplyQuery();
    const body = JSON.stringify({ query });

    const response = await fetch(url, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: body,
    });
    return await response.json();
  };

  return {
    getSupplyInformation
  };
};
