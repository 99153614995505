export const ClaimableTokens = () => {
    const fetchClaimableTokens = async (claimAddress: string) => {
        const url = process.env.REACT_APP_BASE_URL + `api/mint/gettokensforaccount/${claimAddress.toLowerCase()}`;

        const response = await fetch(url, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        });
        return await response.json();
    };

    const getSignature = async (body: any) => {
        const url = process.env.REACT_APP_BASE_URL + 'api/mint/signature';

        const response = await fetch(url, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body),
        });
        return await response.json();
    };

    return {
        fetchClaimableTokens,
        getSignature,
    };
};
