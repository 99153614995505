import { MouseEventHandler, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import koiosLogo from '../../assets/images/logos/koios-logo.svg';
import { Icon } from '../utils/Icon';
import { useSizes } from '../utils/useSizes';
import { WalletConnectButton } from '../WalletConnectButton';

const navigation = [
    {
        title: 'Buy',
        path: '/',
    },
    {
        title: 'Claim',
        path: '/claim',
    },
    {
        title: 'Faucet',
        path: '/faucet',
    },
];

const HeaderNavigation = (props: { toggleMenu: MouseEventHandler<HTMLAnchorElement> | undefined }) => {
    const location = useLocation();

    return (
        <>
            <ul className="navigation container__item">
                {navigation.map((item) => (
                    <li
                        className={`navigation__item ${
                            item.path === location.pathname ? 'navigation__item--active' : ''
                        }`}
                        key={item.title}
                    >
                        <Link className="link" to={item.path} onClick={props.toggleMenu}>
                            {item.title}
                        </Link>
                    </li>
                ))}
            </ul>

            <WalletConnectButton buttonStyle="btn-ghost" />
        </>
    );
};

const Header = () => {
    const { width } = useSizes();
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const isMobile = width < 940;

    const toggleMenu = () => {
        if (isMobile) {
            setIsMenuOpen(!isMenuOpen);
        }
    };

    return (
        <>
            <header className={`header`}>
                <div className="container container--1300">
                    <Link to="/" className="header__img-container">
                        <img className="header__img" src={koiosLogo} alt="Koios logo" />
                    </Link>

                    {isMobile ? (
                        <Icon onClick={toggleMenu} type={isMenuOpen ? 'xmark' : 'hamburger'} />
                    ) : (
                        <HeaderNavigation toggleMenu={toggleMenu} />
                    )}
                </div>
            </header>
            
            {isMenuOpen && (
                <div className="menu-overlay">
                    <HeaderNavigation toggleMenu={toggleMenu} />
                </div>
            )}

        </>
    );
};

export default Header;
