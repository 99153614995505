import Web3 from 'web3';
import { AbiItem } from 'web3-utils';
import { useProvider } from './useProvider';

export const useTokenContract = () => {
    const { getPolygonProvider } = useProvider();
    const web3 = new Web3(getPolygonProvider()); 

    const getUserBalance = async (tokenContractABI: AbiItem[], tokenContractAddress: string, publicKey: string) => {
        const tokenInst = new web3.eth.Contract(tokenContractABI, tokenContractAddress);
        const balance = await tokenInst.methods.balanceOf(publicKey).call();
        const formattedBalance = balance / 10 ** 18;
        return formattedBalance;
      };

    return {
        getUserBalance
    }
}