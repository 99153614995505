import { Icon } from "./utils/Icon";
import { SvgSprite } from "./utils/SvgSprite";

const socialPlatforms = [
    {
        name: 'Opensea',
        icon: 'opensea',
        link: 'https://testnets.opensea.io/collection/koiosnft-tumer0o1zy',
    },
    {
        name: 'Website',
        icon: 'globe',
        link: 'https://app.koios.world/',
    },
    {
        name: 'Discord',
        icon: 'discord',
        link: 'https://discord.gg/koios',
    },
    {
        name: 'Twitter',
        icon: 'twitter',
        link: 'https://twitter.com/koiosdao',
    },
    {
        name: 'GitHub',
        icon: 'github',
        link: 'https://github.com/koiosonline',
    },
];

export const Socials = () => {
    return (
        <ul className="socials">
            {socialPlatforms.map((social) => (
                <li key={social.name}>
                    <a className="socials__item link" href={social.link} target="_blank" rel="noreferrer">
                        <Icon type={social.icon as keyof typeof SvgSprite} />
                    </a>
                </li>
            ))}
        </ul>
    );
};
