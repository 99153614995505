import { useContext, useEffect, useRef, useState } from 'react';
import { getClaimTokens } from '../api/Api';
import ABI from '../assets/contracts/rinkeby/faucet-abi.json';
import Web3 from 'web3';
import { Icon } from '../components/utils/Icon';
import { AuthContext } from '../context/AuthContext';
import { switchToNetworkChain } from '../components/switchToNetworkChain';
import { polygonChain } from '../components/networkChains';
import { useProvider } from '../components/hooks/useProvider';
import { PageHeader } from '../components/layout/PageHeader';
import { Alert } from '../components/utils/Alert';
import ReCAPTCHA from 'react-google-recaptcha';

const Faucet = () => {
    const { getCurrentProvider, getPolygonProvider } = useProvider();
    const [hasClaimed, setHasClaimed] = useState();
    const { userInfo } = useContext(AuthContext);
    const [apiResonse, setApiresonse] = useState({ message: '' });
    const [captchaToken, setCaptchaToken] = useState('');
    const [isClaiming, setIsClaiming] = useState(false);
    const web3 = new Web3(getPolygonProvider());
    const contract = new web3.eth.Contract(JSON.parse(ABI.result), '0xB0b2f8DE0Ff389B154b2a0aAbA1394122F92DF1D');
    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const siteKey = process.env.REACT_APP_CAPTCHA_KEY;

    const updateCaptchaToken = (validToken: any) => {
        setCaptchaToken(validToken);
    };

    const claimTokens = async (claimAddress: string) => {
        await switchToNetworkChain(polygonChain, getCurrentProvider());
        setIsClaiming(true);
        const response = await getClaimTokens(claimAddress, captchaToken);
        if (response) {
            setIsClaiming(false);
            setApiresonse(response);
        }
    };

    useEffect(() => {
        const checkWallet = async () => {
            const data = await contract.methods.claimedList(userInfo?.account).call();
            setHasClaimed(data);
        };

        if (userInfo?.account) {
            checkWallet();
        }
    }, [userInfo?.account, apiResonse, contract.methods]);

    useEffect(() => {
        recaptchaRef.current?.reset();
    }, []);

    return (
        <div className="faucet-page container">
            <PageHeader title="Faucet" />

            <section className="card-faucet">
                <h2 className="card-faucet__title">Matic Faucet</h2>
                <h3 className="card-faucet__subtitle">Get a one-time free 0.01 MATIC</h3>

                <div className={'content-container'}>
                    {userInfo?.account && !hasClaimed && (
                        <>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                onChange={updateCaptchaToken}
                                theme="dark"
                                sitekey={siteKey || ''}
                            />
                            
                            <button
                                onClick={() => (!isClaiming ? claimTokens(userInfo?.account) : '')}
                                className={`btn btn-primary ${isClaiming ? 'btn-pending' : ''}`}
                                disabled={isClaiming || hasClaimed}
                            >
                                {isClaiming ? (
                                    <>
                                        <Icon type="spinner" />
                                        Claiming
                                    </>
                                ) : (
                                    <>Claim Matic</>
                                )}
                            </button>
                            {apiResonse.message && <Alert message={apiResonse.message as string} type={'error'} />}
                        </>
                    )}

                    {hasClaimed && <Alert message={'Address has already claimed from the faucet.'} type={'warning'} />}
                </div>
            </section>
        </div>
    );
};
export default Faucet;
