import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import useWeb3 from './useWeb3';
import { noop } from './utils/noop';

type WalletConnectButtonProps = {
    buttonStyle?: string;
    className?: string;
}

export const WalletConnectButton: React.FC<WalletConnectButtonProps> = (props) => {
    const { userInfo } = useContext(AuthContext);
    const { onConnect, onDisconnect } = useWeb3();
    const [buttonText, setButtonText] = useState<string>('');
    const initialButtonText = userInfo ? userInfo.formattedPublicKey : 'Connect MetaMask';

    useEffect(() => {
        setButtonText(initialButtonText)
    }, [initialButtonText])


    return (
        <button
            className={`btn btn--fs-13 ${props.buttonStyle ? props.buttonStyle : 'btn-primary'} ${props.className ? props.className : ''}`}
            onClick={userInfo ? () => onDisconnect() : () => onConnect()}
            onMouseEnter={userInfo ? () => setButtonText('Disconnect') : noop}
            onMouseLeave={userInfo ? () => setButtonText(initialButtonText) : noop}
        >
            {buttonText}
        </button>
    );
};
