import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

export const useProvider = () => {
    const { setAuthError } = useContext(AuthContext);

    const getCurrentProvider = () => {
        let provider;
        if (window.ethereum) {
            provider = window.ethereum;
        } else if (window.web3) {
            provider = window.web3.currentProvider;
        } else {
            console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');
            setAuthError("Non-Ethereum browser detected. You should consider trying MetaMask!");
        }
        return provider;
    };

    const getPolygonProvider = () => {
        const provider = `https://polygon-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_API_KEY_POLYGON}`;
        return provider;
    };

    const rinkebyProvider = () => {
        const provider = `https://eth-rinkeby.alchemyapi.io/v2/${process.env.REACT_APP_ALCHEMY_API_KEY_RINKEBY}`;
        return provider;
    };

    const ethereumProvider = () => {
        const provider = `https://eth-mainnet.alchemyapi.io/v2/${process.env.REACT_APP_ALCHEMY_API_KEY_ETHEREUM}`;
        return provider;
    };

    return {
        getCurrentProvider,
        getPolygonProvider,
        rinkebyProvider,
        ethereumProvider
    };
};