import './assets/styling/koios.scss';
import { HomePage } from './pages/HomePage';
import './assets/styling/koios.scss';
import { AuthContext, AuthContextProvider } from './context/AuthContext';
import { Route, BrowserRouter, Routes, Navigate, useLocation } from 'react-router-dom';
import { ClaimPage } from './Claim/pages/ClaimPage';
import { useContext } from 'react';
import Header from './components/layout/Header';
import { BuyPage } from './pages/BuyPage';
import { QueryClient, QueryClientProvider } from 'react-query';
import Faucet from './pages/Faucet';
import { Footer } from './components/layout/Footer';
import { ClaimContextProvider } from './context/ClaimContext';
import { Error404 } from './Error/404';

function RequireAuth({ children }: { children: JSX.Element }) {
    const { isAuthenticated } = useContext(AuthContext);
    const location = useLocation();

    if (!isAuthenticated) {
        // Redirect them to the /connect page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login.
        return <Navigate to="/connect" replace state={{ from: location }} />;
    }
    return children;
}

function App() {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <AuthContextProvider>
                <ClaimContextProvider>
                    <BrowserRouter>
                        <Header />
                        <Routes>
                            <Route path="/connect" element={<HomePage />} />
                            <Route
                                path="/"
                                element={
                                    <RequireAuth>
                                        <BuyPage />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="/claim"
                                element={
                                    <RequireAuth>
                                        <ClaimPage />
                                    </RequireAuth>
                                }
                            />
                            <Route
                                path="faucet"
                                element={
                                    <RequireAuth>
                                        <Faucet />
                                    </RequireAuth>
                                }
                            />
                            <Route path="*" element={<Error404 />} />
                        </Routes>
                        <Footer />
                    </BrowserRouter>
                </ClaimContextProvider>
            </AuthContextProvider>
        </QueryClientProvider>
    );
}

export default App;
