import { NetworkChainProps } from '../types/NetworkChainProps';
import { ProviderProps } from '../types/ProviderProps';
import { addNetworkToWallet } from './addNetworkToWallet';
import { polygonChain } from './networkChains';

export const switchToNetworkChain = async (chain: NetworkChainProps, provider: ProviderProps) => {
  try {
    await provider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: chain.chainId }],
    });
    console.log('You are on the right network');
  } catch (switchNetworkError) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchNetworkError.code === 4902) {
      console.log('This network is not available in your metamask, please add it');
      return await addNetworkToWallet(polygonChain, provider);
      
    }
    throw switchNetworkError.message;
  }
};
