import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { Socials } from './Socials';
import { Alert } from './utils/Alert';
import { WalletConnectButton } from './WalletConnectButton';

const Hero = () => {
    const { authError } = useContext(AuthContext);

    return (
        <div className="hero">
            <h2 className="hero__subtitle">Koios Titan Collection</h2>

            <h1 className="hero__title">
                Become a <span className="highlight">Titan</span>
            </h1>

            <p className="hero__description">
                You'll be part of the{' '}
                <a href="https://discord.gg/koios" target="_blank" rel="noreferrer" className="link highlight">
                    Koios community
                </a>
                .
            </p>
            <p className="hero__description">Learn. Earn. Return.</p>

            <div className="hero__call-to-actions">
                <a
                    className="btn btn-ghost btn--fs-13 link"
                    href="https://wiki.koios.world/"
                    target="_blank"
                    rel="noreferrer"
                >
                    Visit Roadmap
                </a>

                <WalletConnectButton className='btn--wallet-connect' />

                {authError && <Alert message={authError} type="error" />}
            </div>

            <Socials />
        </div>
    );
};

export default Hero;
