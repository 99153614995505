export const NftSupplyQuery = () => {
    return `
    {
      contractInfos(first: 1000) {
        totalSupply
        totalMinted
      }
    }
    
  `;
};
