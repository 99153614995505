import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Web3 from "web3";
import { AuthContext } from "../context/AuthContext";
import { useProvider } from "./hooks/useProvider";
import { formatPublicKey } from "./utils/formatPublicKey";

declare global {
  interface Window {
    ethereum: any;
    web3: any;
  }
}
interface LocationState {
  from: {
    pathname: string;
  };
}

const useWeb3 = () => {
  const { isAuthenticated, setIsAuthenticated, setUserInfo } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const from = (location.state as LocationState)?.from;
  const { getCurrentProvider } = useProvider();

  const onConnect = async () => {
    try {
      const currentProvider = getCurrentProvider();
      if (currentProvider) {
        await currentProvider.request({ method: "eth_requestAccounts" });
        const web3 = new Web3(currentProvider);
        const userAccount = await web3.eth.getAccounts();
        const chainId = await web3.eth.getChainId();
        const account = userAccount[0];
        let ethBalance = await web3.eth.getBalance(account); // Get wallet balance
        ethBalance = web3.utils.fromWei(ethBalance, "ether"); //Convert balance to wei
        saveUserInfo(ethBalance, account, chainId);

        if (from) {
          navigate(from, {replace: true});
        }

        if (userAccount.length === 0) {
          console.log("Please connect to meta mask");
        }
      }
    } catch (err) {
      console.log("There was an error fetching your accounts. Make sure your Ethereum client is configured correctly.");
    }
  };

  const saveUserInfo = (ethBalance: string, account: string, chainId: number) => {
    const userAccount = {
      account: account,
      formattedPublicKey: formatPublicKey(account),
      balance: ethBalance,
      connectionid: chainId,
    };
    window.localStorage.setItem("userAccount", JSON.stringify(userAccount)); //user persisted data
    const userData = JSON.parse(localStorage.getItem("userAccount")!);
    setUserInfo(userData);
    setIsAuthenticated(true);
  };

  const onDisconnect = () => {
    window.localStorage.removeItem("userAccount");
    setUserInfo(null);
    setIsAuthenticated(false);
  };



  return {
    onConnect,
    onDisconnect,
    saveUserInfo,
    isAuthenticated
  };
};

export default useWeb3;
