import { useContext, useEffect, useState } from 'react';
import Web3 from 'web3';
import placeholderNFT from '../../assets/images/placeholders/placeholder-titan.png';
import { AuthContext } from '../../context/AuthContext';
import { ClaimContext } from '../../context/ClaimContext';
import { CardUnrevealedProps } from '../../types/CardUnrevealedProps';
import { useProvider } from '../hooks/useProvider';
import { claimTitanNFT } from '../../Claim/helpers/claimTitanNFT';

const CardUnrevealed = (props: CardUnrevealedProps) => {
    const { getCurrentProvider } = useProvider();
    const { userInfo } = useContext(AuthContext);
    const [isClaiming, setIsClaiming] = useState<boolean>(false);
    const [transactionHash, setTransactionHash] = useState<string>('');
    const web3 = new Web3(getCurrentProvider());
    const { setClaimConfirmation } = useContext(ClaimContext);

    useEffect(() => {
        const getReceipt = async () => {
            if (transactionHash) {
                const receipt = await web3.eth.getTransactionReceipt(transactionHash);
                return setClaimConfirmation(receipt?.status);
            }
            return setClaimConfirmation(false);
        };
        getReceipt();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isClaiming, transactionHash]);

    return (
        <div className={`card card-unrevealed`}>
            <img className="card__img" src={placeholderNFT} alt="NFT" />

            <div className="card__heading">
                <h3 className={`card__name`}>Unrevealed Titan #{props.tokenId}</h3>
            </div>

            <button
                className="btn btn-primary btn--rounded"
                disabled={isClaiming}
                onClick={() =>
                    claimTitanNFT(
                        props,
                        getCurrentProvider(),
                        userInfo.account,
                        setIsClaiming,
                        setTransactionHash
                    )
                }
            >
                {isClaiming ? 'Claiming in progress' : 'Claim'}
            </button>
        </div>
    );
};

export default CardUnrevealed;
