import nftTitanContractABI from '../../assets/contracts/ethereum/nft-titan-contract-abi-ethereum.json';
import { AbiItem } from 'web3-utils';
import { CardUnrevealedProps } from '../../types/CardUnrevealedProps';
import { switchToNetworkChain } from '../../components/switchToNetworkChain';
import { ethereumChain } from '../../components/networkChains';
import Web3 from 'web3';
import { Dispatch, SetStateAction } from 'react';
import { ClaimableTokens } from '../../api/ClaimableTokens';

export const claimTitanNFT = async (
    claim: CardUnrevealedProps,
    provider: any,
    accountAddress: string,
    setIsClaiming: Dispatch<SetStateAction<boolean>>,
    setTransactionHash: Dispatch<SetStateAction<string>>
) => {
    const { getSignature } = ClaimableTokens();
    const contractAddress = process.env.REACT_APP_TITAN_NFT_CONTRACT_ADDRESS_ETHEREUM;
    const web3 = new Web3(provider);
    const contract = new web3.eth.Contract(nftTitanContractABI as AbiItem[], contractAddress as string);

    try {
        setIsClaiming(true);
        await switchToNetworkChain(ethereumChain, provider);
        const signature = await getSignature(claim);

        contract.methods
            .claim(signature.proof.salt, signature.proof.token, signature.proof.tokenId)
            .send({ from: accountAddress, maxPriorityFeePerGas: null, maxFeePerGas: null })
            .on('error', (error: Error) => {
                setIsClaiming(false);
                throw error.message;
            })
            .on('transactionHash', (transactionHash: string) => {
                setTransactionHash(transactionHash);
            })
            .then(() => {
                setIsClaiming(false);
            });
    } catch (e) {
        setIsClaiming(false);
        console.log(e.message);
    }
};
