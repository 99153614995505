import CardUnrevealed from '../../components/cards/CardUnrevealed';
import { PageHeader } from '../../components/layout/PageHeader';
import { CardUnrevealedProps } from '../../types/CardUnrevealedProps';

export const Claim = ({ tokens }: any) => {
    return (
        <>
            <PageHeader title="Available to claim" />

            <ul className="claim-page__list">
                {tokens.map((item: CardUnrevealedProps, index: number) => (
                    <li key={index}>
                        <CardUnrevealed {...item} />
                    </li>
                ))}
            </ul>
        </>
    );
};
