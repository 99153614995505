import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { useNftContract } from '../../components/hooks/useNftContract';
import { ClaimableTokens } from '../../api/ClaimableTokens';
import { ClaimContext } from '../../context/ClaimContext';
import { ClaimConfirmation } from '../components/ClaimConfirmation';
import { ClaimUnavailable } from '../components/ClaimUnavailable';
import { Claim } from '../components/Claim';

export const ClaimPage = () => {
    const { userInfo } = useContext(AuthContext);
    const { getUnclaimedTokens } = useNftContract();
    const [claimableTokens, setClaimableTokens] = useState<object[]>([]);
    const { fetchClaimableTokens } = ClaimableTokens();
    const { claimConfirmation } = useContext(ClaimContext);
    const hasClaimableTokens = claimableTokens.length > 0;

    const getClaimableTokens = async () => {
        const claimableTokens = await fetchClaimableTokens(userInfo.account);
        
        const unClaimedTokens = claimableTokens.tokens.map(async (token: any) => {
            return {
                tokenId: token.tokenId,
                claimAddress: token.claimAddress,
                isClaimed: await getUnclaimedTokens(token.tokenId),
            };
        });
        const unClaimedTokenResolved = await Promise.all(unClaimedTokens);
        const filtered = unClaimedTokenResolved.filter((item) => item.isClaimed === false);
        setClaimableTokens(filtered);
    };

    useEffect(() => {
        getClaimableTokens();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [claimConfirmation]);

    return (
        <div className="claim-page container">
            {claimConfirmation && <ClaimConfirmation />}
            {!claimConfirmation && hasClaimableTokens && <Claim tokens={claimableTokens} />}
            {!claimConfirmation && !hasClaimableTokens && <ClaimUnavailable />}
        </div>
    );
};
