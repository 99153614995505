import { PageHeader } from '../../components/layout/PageHeader';

export const ClaimUnavailable = () => {
    return (
        <>
            <PageHeader title="Available to claim" />

            <p className="claim-page__warning">
                Nothing to claim. Make sure you bought a Titan and waited till the{' '}
                <a
                    href="https://wiki.koios.world/important-info/how-to-revive-a-titan-nft"
                    target="_blank"
                    rel="noreferrer"
                    className="claim-page__warning--highlight link"
                >
                    time before claiming
                </a>{' '}
                has expired.
            </p>
        </>
    );
};
