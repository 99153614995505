import { switchToNetworkChain } from '../switchToNetworkChain';
import titanCoinContractABI from '../../assets/contracts/polygon/titan-coin-contract-abi.json';
import { AbiItem } from 'web3-utils';
import { polygonChain } from '../networkChains';
import Web3 from 'web3';
import { Dispatch, SetStateAction } from 'react';

export const transferTitanCoin = async (
    provider: any,
    currentAccount: string,
    setIsBuying: Dispatch<SetStateAction<boolean>>,
    setTransactionHash: Dispatch<SetStateAction<string>>,
    setErrorStatus: Dispatch<SetStateAction<string>>
) => {
    const titanCoinContractAddress = process.env.REACT_APP_TITAN_COIN_CONTRACT_ADDRESS_POLYGON;
    const web3 = new Web3(provider);
    const contractInstance = new web3.eth.Contract(
        titanCoinContractABI as AbiItem[],
        titanCoinContractAddress as string
    );
    const recipientAddress = process.env.REACT_APP_RECIPIENT_ADDRESS;
    const amountToPay = BigInt(10 * (10 ** 18));

    try {
        setIsBuying(true);
        await switchToNetworkChain(polygonChain, provider);
        await contractInstance.methods
            .transfer(recipientAddress, amountToPay)
            .send({ from: currentAccount, gas: 70000, maxPriorityFeePerGas: null, maxFeePerGas: null })
            .on('error', (error: Error) => {
                throw error.message;
            })
            .on('transactionHash', (transactionHash: string) => {
                setTransactionHash(transactionHash);
            })
            .then(() => {
                setIsBuying(false);
            });
    } catch (e) {
        setIsBuying(false);
        setTransactionHash('');
        setErrorStatus(e.message);
    }
};
