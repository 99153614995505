type PageHeaderProps = {
    title: string;
}

export const PageHeader = ({ title }: PageHeaderProps) => {
    return (
        <section className="page-header">
            <h1 className="page-header__title">{title}</h1>
        </section>
    );
};
