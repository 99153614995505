import { createContext, useState } from 'react';

export const ClaimContext = createContext<any>(null);

export const ClaimContextProvider = (props: any) => {
    const [claimConfirmation, setClaimConfirmation] = useState<boolean>(false);

    const providerValue = {
        claimConfirmation,
        setClaimConfirmation,
    };

    return <ClaimContext.Provider value={providerValue}>{props.children}</ClaimContext.Provider>;
};
