import { NetworkChainProps } from "../types/NetworkChainProps";
import { ProviderProps } from "../types/ProviderProps";

export const addNetworkToWallet = async (
  chain: NetworkChainProps,
  provider: ProviderProps
) => {
  try {
    await provider.request({
      method: "wallet_addEthereumChain",
      params: [chain],
    });
    await provider.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: chain.chainId }],
    });
  } catch (e) {
    if (e instanceof Error) {
      console.log(e.message);
    } else {
      console.log("Unexpected error", e);
    }
  }
};
